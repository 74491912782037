@use "./shared.scss" as *;
@use "./themed.scss" as *;

html {
  @include is_larger_than($md-breakpoint) {
    margin-left: calc(100vw - 100%);
    margin-right: 0;
  }
}

body {
  font-family: $font-family;
  font-weight: 300;
  padding: 0;

  @include themed() {
    background: t($color-background-default);
    color: t($color-text-default);
  }
}

p {
  line-height: 1.5rem;

  @include is_larger_than($sm-breakpoint) {
    text-align: justify;
  }
}
