html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

@keyframes animation-fade-pulse_lightmode {
  0% {
    opacity: .3;
    background: #a99;
  }

  40% {
    opacity: 1;
    background: #a77;
  }

  80% {
    opacity: .3;
    background: #a99;
  }
}

@keyframes animation-fade-pulse_darkmode {
  0% {
    opacity: .3;
    background: #a9a;
  }

  40% {
    opacity: 1;
    background: #a7a;
  }

  80% {
    opacity: .3;
    background: #a9a;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animation-delayed-fade {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes turbulence {
  0% {
    filter: url("#turbulence_0");
  }

  12.5% {
    filter: url("#turbulence_1");
  }

  25% {
    filter: url("#turbulence_2");
  }

  37.5% {
    filter: url("#turbulence_3");
  }

  50% {
    filter: url("#turbulence_4");
  }

  62.5% {
    filter: url("#turbulence_5");
  }

  75% {
    filter: url("#turbulence_6");
  }

  100% {
    filter: url("#turbulence_7");
  }
}

._iconButton-module__icon_button {
  height: 2.75rem;
  width: 2.75rem;
  background-color: initial;
  cursor: pointer;
  border: none;
  padding: 0;
}

@media (hover: hover) {
  ._iconButton-module__icon_button:after {
    pointer-events: none;
    opacity: 0;
    content: "";
    width: calc(100% - .5rem);
    height: calc(100% - .5rem);
    border-radius: 2px;
    transition: opacity .2s cubic-bezier(.45, .05, .55, .95);
    position: absolute;
    inset: .25rem;
    overflow: hidden;
  }

  .theme--lightmode ._iconButton-module__icon_button:after {
    color: #211;
    background: #5d4444;
  }

  .theme--transition ._iconButton-module__icon_button:after {
    transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
  }

  .theme--darkmode ._iconButton-module__icon_button:after {
    color: #ede;
    background: #cdcccd;
  }

  .theme--transition ._iconButton-module__icon_button:after {
    transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
  }

  ._iconButton-module__icon_button:hover:after {
    opacity: .2;
  }

  ._iconButton-module__icon_button:active:after {
    opacity: .4;
    transition: none;
  }

  .theme--lightmode ._iconButton-module__icon_button:active:after {
    color: #211;
    background: #5d4444;
  }

  .theme--transition ._iconButton-module__icon_button:active:after {
    transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
  }

  .theme--darkmode ._iconButton-module__icon_button:active:after {
    color: #ede;
    background: #cdcccd;
  }

  .theme--transition ._iconButton-module__icon_button:active:after {
    transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
  }
}

._iconButton-module__icon_button > svg {
  width: 60%;
  height: 60%;
  stroke-width: 3px;
  padding: 20%;
}

.theme--lightmode ._iconButton-module__icon_button > svg {
  stroke: #a77;
}

.theme--transition ._iconButton-module__icon_button > svg {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--darkmode ._iconButton-module__icon_button > svg {
  stroke: #a7a;
}

.theme--transition ._iconButton-module__icon_button > svg {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

@keyframes animation-fade-pulse_lightmode {
  0% {
    opacity: .3;
    background: #a99;
  }

  40% {
    opacity: 1;
    background: #a77;
  }

  80% {
    opacity: .3;
    background: #a99;
  }
}

@keyframes animation-fade-pulse_darkmode {
  0% {
    opacity: .3;
    background: #a9a;
  }

  40% {
    opacity: 1;
    background: #a7a;
  }

  80% {
    opacity: .3;
    background: #a9a;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animation-delayed-fade {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes turbulence {
  0% {
    filter: url("#turbulence_0");
  }

  12.5% {
    filter: url("#turbulence_1");
  }

  25% {
    filter: url("#turbulence_2");
  }

  37.5% {
    filter: url("#turbulence_3");
  }

  50% {
    filter: url("#turbulence_4");
  }

  62.5% {
    filter: url("#turbulence_5");
  }

  75% {
    filter: url("#turbulence_6");
  }

  100% {
    filter: url("#turbulence_7");
  }
}

._layout-module__layout {
  max-width: 1400px;
  margin: auto;
}

._layout-module__layout > main {
  margin: 0 1rem 2rem;
}

._layout-module__layout > nav {
  border-radius: 0;
  margin: 2rem 1rem;
  position: relative;
  overflow: hidden;
}

.theme--lightmode ._layout-module__layout > nav {
  background-color: #0000;
}

.theme--transition ._layout-module__layout > nav {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--darkmode ._layout-module__layout > nav {
  background-color: #0000;
}

.theme--transition ._layout-module__layout > nav {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

._layout-module__layout > nav ul {
  z-index: 0;
  min-height: 2.75rem;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: relative;
}

@media only screen and (min-width: 768px) {
  ._layout-module__layout > nav ul {
    flex-direction: row;
    margin-right: 3.5rem;
  }
}

._layout-module__layout > nav li {
  height: 2.75rem;
  width: 100%;
}

._layout-module__layout > nav li a {
  text-align: center;
  cursor: pointer;
  height: 100%;
  width: 100%;
  vertical-align: middle;
  font-size: 1.2rem;
  line-height: 2.75rem;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.theme--lightmode ._layout-module__layout > nav li a {
  color: #211;
}

.theme--transition ._layout-module__layout > nav li a {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--darkmode ._layout-module__layout > nav li a {
  color: #ede;
}

.theme--transition ._layout-module__layout > nav li a {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

._layout-module__layout > nav li._layout-module__logo {
  z-index: 1;
  width: calc(100% - 5rem);
  flex: 1;
  margin: 0 2.5rem 1.7rem;
}

@media only screen and (min-width: 768px) {
  ._layout-module__layout > nav li._layout-module__logo {
    width: 100%;
    margin: 0;
  }
}

._layout-module__layout > nav li._layout-module__logo a {
  width: 100%;
  white-space: nowrap;
  height: .5rem;
  padding: 1.125rem 0;
  font-size: 2.2rem;
  line-height: .25rem;
}

@media only screen and (min-width: 768px) {
  ._layout-module__layout > nav li._layout-module__logo a {
    width: min-content;
    padding: 1.125rem 0;
  }

  ._layout-module__layout > nav li._layout-module__logo a:before {
    content: "{";
    padding-right: .75rem;
  }

  ._layout-module__layout > nav li._layout-module__logo a:after {
    content: "}";
    padding-left: .75rem;
  }

  ._layout-module__layout > nav li._layout-module__logo a:before, ._layout-module__layout > nav li._layout-module__logo a:after {
    font-size: 1.6rem;
    transition: transform .1s ease-out, text-shadow .1s ease-out;
    display: inline-block;
    transform: translateY(-4px);
  }

  .theme--lightmode ._layout-module__layout > nav li._layout-module__logo a:before, .theme--lightmode ._layout-module__layout > nav li._layout-module__logo a:after {
    color: #a77;
  }

  .theme--transition ._layout-module__layout > nav li._layout-module__logo a:before, .theme--transition ._layout-module__layout > nav li._layout-module__logo a:after {
    transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
  }

  .theme--darkmode ._layout-module__layout > nav li._layout-module__logo a:before, .theme--darkmode ._layout-module__layout > nav li._layout-module__logo a:after {
    color: #a7a;
  }

  .theme--transition ._layout-module__layout > nav li._layout-module__logo a:before, .theme--transition ._layout-module__layout > nav li._layout-module__logo a:after {
    transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
  }
}

@media only screen and (min-width: 768px) and (hover: hover) {
  ._layout-module__layout > nav li._layout-module__logo a:hover:before, ._layout-module__layout > nav li._layout-module__logo a:hover:after {
    transform: translateY(-5px)scale(1.1);
  }

  .theme--lightmode ._layout-module__layout > nav li._layout-module__logo a:hover:before {
    text-shadow: -1px 0 #a77;
  }

  .theme--transition ._layout-module__layout > nav li._layout-module__logo a:hover:before {
    transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
  }

  .theme--darkmode ._layout-module__layout > nav li._layout-module__logo a:hover:before {
    text-shadow: -1px 0 #a7a;
  }

  .theme--transition ._layout-module__layout > nav li._layout-module__logo a:hover:before {
    transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
  }

  .theme--lightmode ._layout-module__layout > nav li._layout-module__logo a:hover:after {
    text-shadow: 1px 0 #a77;
  }

  .theme--transition ._layout-module__layout > nav li._layout-module__logo a:hover:after {
    transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
  }

  .theme--darkmode ._layout-module__layout > nav li._layout-module__logo a:hover:after {
    text-shadow: 1px 0 #a7a;
  }

  .theme--transition ._layout-module__layout > nav li._layout-module__logo a:hover:after {
    transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
  }
}

._layout-module__layout > nav li._layout-module__navlink {
  flex: 1;
  position: relative;
}

@media only screen and (min-width: 768px) {
  ._layout-module__layout > nav li._layout-module__navlink {
    flex: 0 120px;
  }
}

._layout-module__layout > nav li._layout-module__navlink > a {
  cursor: pointer;
}

@media (hover: hover) {
  ._layout-module__layout > nav li._layout-module__navlink > a:after {
    pointer-events: none;
    opacity: 0;
    content: "";
    width: calc(100% - .5rem);
    height: calc(100% - .5rem);
    border-radius: 2px;
    transition: opacity .2s cubic-bezier(.45, .05, .55, .95);
    position: absolute;
    inset: .25rem;
    overflow: hidden;
  }

  .theme--lightmode ._layout-module__layout > nav li._layout-module__navlink > a:after {
    color: #211;
    background: #5d4444;
  }

  .theme--transition ._layout-module__layout > nav li._layout-module__navlink > a:after {
    transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
  }

  .theme--darkmode ._layout-module__layout > nav li._layout-module__navlink > a:after {
    color: #ede;
    background: #cdcccd;
  }

  .theme--transition ._layout-module__layout > nav li._layout-module__navlink > a:after {
    transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
  }

  ._layout-module__layout > nav li._layout-module__navlink > a:hover:after {
    opacity: .2;
  }

  ._layout-module__layout > nav li._layout-module__navlink > a:active:after {
    opacity: .4;
    transition: none;
  }

  .theme--lightmode ._layout-module__layout > nav li._layout-module__navlink > a:active:after {
    color: #211;
    background: #5d4444;
  }

  .theme--transition ._layout-module__layout > nav li._layout-module__navlink > a:active:after {
    transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
  }

  .theme--darkmode ._layout-module__layout > nav li._layout-module__navlink > a:active:after {
    color: #ede;
    background: #cdcccd;
  }

  .theme--transition ._layout-module__layout > nav li._layout-module__navlink > a:active:after {
    transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
  }
}

._layout-module__layout > nav li._layout-module__current a {
  text-decoration: underline;
}

@media only screen and (min-width: 768px) {
  ._layout-module__layout > nav li._layout-module__navlink:after {
    content: "";
    opacity: .2;
    height: 60%;
    width: 1px;
    position: absolute;
    top: 20%;
    right: -2px;
  }

  .theme--lightmode ._layout-module__layout > nav li._layout-module__navlink:after {
    border-left: 2px solid #5d4444;
  }

  .theme--transition ._layout-module__layout > nav li._layout-module__navlink:after {
    transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
  }

  .theme--darkmode ._layout-module__layout > nav li._layout-module__navlink:after {
    border-left: 2px solid #cdcccd;
  }

  .theme--transition ._layout-module__layout > nav li._layout-module__navlink:after {
    transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
  }
}

@media only screen and (max-width: 767px) {
  ._layout-module__layout > nav li._layout-module__navlink:not(:first-child):not(:last-child):after {
    content: "";
    opacity: .2;
    width: 50%;
    height: 1px;
    position: absolute;
    bottom: -2px;
    left: 25%;
  }

  .theme--lightmode ._layout-module__layout > nav li._layout-module__navlink:not(:first-child):not(:last-child):after {
    border-top: 2px solid #5d4444;
  }

  .theme--transition ._layout-module__layout > nav li._layout-module__navlink:not(:first-child):not(:last-child):after {
    transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
  }

  .theme--darkmode ._layout-module__layout > nav li._layout-module__navlink:not(:first-child):not(:last-child):after {
    border-top: 2px solid #cdcccd;
  }

  .theme--transition ._layout-module__layout > nav li._layout-module__navlink:not(:first-child):not(:last-child):after {
    transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
  }
}

._layout-module__layout > nav ul {
  max-height: 2.75rem;
}

@media only screen and (min-width: 768px) {
  ._layout-module__layout > nav ul {
    max-height: inherit;
  }
}

._layout-module__layout > nav li._layout-module__navlink {
  display: none;
}

@media only screen and (min-width: 768px) {
  ._layout-module__layout > nav li._layout-module__navlink {
    display: inherit;
  }
}

._layout-module__layout > nav._layout-module__expanded ul {
  max-height: inherit;
}

._layout-module__layout > nav._layout-module__expanded li._layout-module__navlink {
  display: inherit;
}

._layout-module__menu_button {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (min-width: 768px) {
  ._layout-module__menu_button {
    display: none;
  }
}

._layout-module__theme_toggle {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
}

@keyframes animation-fade-pulse_lightmode {
  0% {
    opacity: .3;
    background: #a99;
  }

  40% {
    opacity: 1;
    background: #a77;
  }

  80% {
    opacity: .3;
    background: #a99;
  }
}

@keyframes animation-fade-pulse_darkmode {
  0% {
    opacity: .3;
    background: #a9a;
  }

  40% {
    opacity: 1;
    background: #a7a;
  }

  80% {
    opacity: .3;
    background: #a9a;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animation-delayed-fade {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes turbulence {
  0% {
    filter: url("#turbulence_0");
  }

  12.5% {
    filter: url("#turbulence_1");
  }

  25% {
    filter: url("#turbulence_2");
  }

  37.5% {
    filter: url("#turbulence_3");
  }

  50% {
    filter: url("#turbulence_4");
  }

  62.5% {
    filter: url("#turbulence_5");
  }

  75% {
    filter: url("#turbulence_6");
  }

  100% {
    filter: url("#turbulence_7");
  }
}

._loader-module__loader {
  text-align: center;
  margin: 2rem 1rem 1rem;
  animation: 1s ease-in-out animation-delayed-fade;
}

._loader-module__loader span {
  width: 1rem;
  height: 2rem;
  opacity: .3;
  margin: .25rem;
  display: inline-block;
}

.theme--lightmode ._loader-module__loader span {
  background: #a99;
  animation: 1.5s ease-in-out infinite animation-fade-pulse_lightmode;
}

.theme--transition ._loader-module__loader span {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--darkmode ._loader-module__loader span {
  background: #a9a;
  animation: 1.5s ease-in-out infinite animation-fade-pulse_darkmode;
}

.theme--transition ._loader-module__loader span {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

._loader-module__loader span:nth-child(4n+1) {
  animation-delay: 0s;
}

._loader-module__loader span:nth-child(4n+2) {
  animation-delay: .25s;
}

._loader-module__loader span:nth-child(4n+3) {
  animation-delay: .5s;
}

._loader-module__loader span:nth-child(4n+4) {
  animation-delay: .75s;
}

@keyframes animation-fade-pulse_lightmode {
  0% {
    opacity: .3;
    background: #a99;
  }

  40% {
    opacity: 1;
    background: #a77;
  }

  80% {
    opacity: .3;
    background: #a99;
  }
}

@keyframes animation-fade-pulse_darkmode {
  0% {
    opacity: .3;
    background: #a9a;
  }

  40% {
    opacity: 1;
    background: #a7a;
  }

  80% {
    opacity: .3;
    background: #a9a;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animation-delayed-fade {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes turbulence {
  0% {
    filter: url("#turbulence_0");
  }

  12.5% {
    filter: url("#turbulence_1");
  }

  25% {
    filter: url("#turbulence_2");
  }

  37.5% {
    filter: url("#turbulence_3");
  }

  50% {
    filter: url("#turbulence_4");
  }

  62.5% {
    filter: url("#turbulence_5");
  }

  75% {
    filter: url("#turbulence_6");
  }

  100% {
    filter: url("#turbulence_7");
  }
}

._frame-module__frame {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

._frame-module__frame > svg:last-child {
  z-index: -1;
  position: relative;
}

._frame-module__frame > :first-child {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  position: absolute;
  inset: 0;
}

._frame-module__frame._frame-module__hidden > :first-child {
  display: none;
}

._frame-module__frame:not(._frame-module__hidden) > :first-child {
  animation: .3s ease-in fadein;
}

._frame-module__frame ._frame-module__fullscreen_button {
  width: 2rem;
  height: 2rem;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  position: absolute;
  inset: .5rem .5rem auto auto;
}

._frame-module__frame:hover > ._frame-module__fullscreen_button {
  opacity: .8;
}

@keyframes animation-fade-pulse_lightmode {
  0% {
    opacity: .3;
    background: #a99;
  }

  40% {
    opacity: 1;
    background: #a77;
  }

  80% {
    opacity: .3;
    background: #a99;
  }
}

@keyframes animation-fade-pulse_darkmode {
  0% {
    opacity: .3;
    background: #a9a;
  }

  40% {
    opacity: 1;
    background: #a7a;
  }

  80% {
    opacity: .3;
    background: #a9a;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animation-delayed-fade {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes turbulence {
  0% {
    filter: url("#turbulence_0");
  }

  12.5% {
    filter: url("#turbulence_1");
  }

  25% {
    filter: url("#turbulence_2");
  }

  37.5% {
    filter: url("#turbulence_3");
  }

  50% {
    filter: url("#turbulence_4");
  }

  62.5% {
    filter: url("#turbulence_5");
  }

  75% {
    filter: url("#turbulence_6");
  }

  100% {
    filter: url("#turbulence_7");
  }
}

._iframe-module__frame {
  border-radius: 0;
  overflow: hidden;
}

.theme--lightmode ._iframe-module__frame {
  box-shadow: 0 0 0 1px #5d4444;
}

.theme--transition ._iframe-module__frame {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--darkmode ._iframe-module__frame {
  box-shadow: 0 0 0 1px #cdcccd;
}

.theme--transition ._iframe-module__frame {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--lightmode ._iframe-module__frame {
  background-color: #000;
}

.theme--transition ._iframe-module__frame {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--darkmode ._iframe-module__frame {
  background-color: #000;
}

.theme--transition ._iframe-module__frame {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

._iframe-module__frame iframe {
  width: 100%;
  height: 100%;
  border: none;
}

@keyframes animation-fade-pulse_lightmode {
  0% {
    opacity: .3;
    background: #a99;
  }

  40% {
    opacity: 1;
    background: #a77;
  }

  80% {
    opacity: .3;
    background: #a99;
  }
}

@keyframes animation-fade-pulse_darkmode {
  0% {
    opacity: .3;
    background: #a9a;
  }

  40% {
    opacity: 1;
    background: #a7a;
  }

  80% {
    opacity: .3;
    background: #a9a;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animation-delayed-fade {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes turbulence {
  0% {
    filter: url("#turbulence_0");
  }

  12.5% {
    filter: url("#turbulence_1");
  }

  25% {
    filter: url("#turbulence_2");
  }

  37.5% {
    filter: url("#turbulence_3");
  }

  50% {
    filter: url("#turbulence_4");
  }

  62.5% {
    filter: url("#turbulence_5");
  }

  75% {
    filter: url("#turbulence_6");
  }

  100% {
    filter: url("#turbulence_7");
  }
}

._card-module__card {
  border-radius: 0;
  flex-direction: column;
  margin-bottom: 1rem;
  padding: .5rem 1rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.theme--lightmode ._card-module__card {
  box-shadow: 0 0 0 1px #5d4444;
}

.theme--transition ._card-module__card {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--darkmode ._card-module__card {
  box-shadow: 0 0 0 1px #cdcccd;
}

.theme--transition ._card-module__card {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--lightmode ._card-module__card {
  background-color: #0000;
}

.theme--transition ._card-module__card {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--darkmode ._card-module__card {
  background-color: #0000;
}

.theme--transition ._card-module__card {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

._card-module__card ._card-module__svg {
  width: calc(100% - 2rem);
  height: 5rem;
  margin: 1rem;
}

.theme--lightmode ._card-module__card ._card-module__svg {
  background: #a77;
}

.theme--transition ._card-module__card ._card-module__svg {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--darkmode ._card-module__card ._card-module__svg {
  background: #a7a;
}

.theme--transition ._card-module__card ._card-module__svg {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

._card-module__card ._card-module__title {
  padding: 0 .25rem;
  display: flex;
}

._card-module__card ._card-module__title h4 {
  font-size: .95em;
}

._card-module__card header {
  flex: 1 0;
  font-size: 1.2rem;
}

._card-module__card header h4 {
  margin: .5rem 0;
}

._card-module__card footer {
  flex: none;
  padding: .7rem 0;
  font-size: .9rem;
}

._card-module__card p {
  flex: 0 0 100%;
  margin-top: 0;
  padding: 1rem 0 0 .5rem;
  font-size: .9rem;
}

._card-module__card_clickable {
  cursor: pointer;
}

@media (hover: hover) {
  ._card-module__card_clickable:after {
    pointer-events: none;
    opacity: 0;
    content: "";
    width: calc(100% - .5rem);
    height: calc(100% - .5rem);
    border-radius: 2px;
    transition: opacity .2s cubic-bezier(.45, .05, .55, .95);
    position: absolute;
    inset: .25rem;
    overflow: hidden;
  }

  .theme--lightmode ._card-module__card_clickable:after {
    color: #211;
    background: #5d4444;
  }

  .theme--transition ._card-module__card_clickable:after {
    transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
  }

  .theme--darkmode ._card-module__card_clickable:after {
    color: #ede;
    background: #cdcccd;
  }

  .theme--transition ._card-module__card_clickable:after {
    transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
  }

  ._card-module__card_clickable:hover:after {
    opacity: .2;
  }

  ._card-module__card_clickable:active:after {
    opacity: .4;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    transition: none;
    inset: 0;
  }

  .theme--lightmode ._card-module__card_clickable:active:after {
    color: #211;
    background: #5d4444;
  }

  .theme--transition ._card-module__card_clickable:active:after {
    transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
  }

  .theme--darkmode ._card-module__card_clickable:active:after {
    color: #ede;
    background: #cdcccd;
  }

  .theme--transition ._card-module__card_clickable:active:after {
    transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
  }
}

@keyframes animation-fade-pulse_lightmode {
  0% {
    opacity: .3;
    background: #a99;
  }

  40% {
    opacity: 1;
    background: #a77;
  }

  80% {
    opacity: .3;
    background: #a99;
  }
}

@keyframes animation-fade-pulse_darkmode {
  0% {
    opacity: .3;
    background: #a9a;
  }

  40% {
    opacity: 1;
    background: #a7a;
  }

  80% {
    opacity: .3;
    background: #a9a;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animation-delayed-fade {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes turbulence {
  0% {
    filter: url("#turbulence_0");
  }

  12.5% {
    filter: url("#turbulence_1");
  }

  25% {
    filter: url("#turbulence_2");
  }

  37.5% {
    filter: url("#turbulence_3");
  }

  50% {
    filter: url("#turbulence_4");
  }

  62.5% {
    filter: url("#turbulence_5");
  }

  75% {
    filter: url("#turbulence_6");
  }

  100% {
    filter: url("#turbulence_7");
  }
}

._cardAction-module__card_action {
  padding: .5rem 0;
}

._cardAction-module__card_action._cardAction-module__match_height {
  height: calc(100% - 1rem);
}

._cardAction-module__card_action._cardAction-module__match_height > div:first-child {
  flex: 1;
  margin: auto;
}

@media only screen and (min-width: 768px) {
  ._cardAction-module__card_action._cardAction-module__match_height > div:first-child {
    margin: 1rem;
  }
}

._cardAction-module__card_action._cardAction-module__match_height > div:nth-child(2) {
  padding: 0;
}

._cardAction-module__card_action._cardAction-module__match_height header > h4 {
  margin: auto;
}

@media only screen and (min-width: 768px) {
  ._cardAction-module__card_action._cardAction-module__match_height header > h4 {
    margin: .5rem 0;
  }
}

.theme--lightmode ._cardAction-module__card_action {
  box-shadow: 0 0 0 2px #5d444433;
}

.theme--transition ._cardAction-module__card_action {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--darkmode ._cardAction-module__card_action {
  box-shadow: 0 0 0 2px #cdcccd33;
}

.theme--transition ._cardAction-module__card_action {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

._cardAction-module__card_action header > h4 {
  text-align: center;
  font-size: 1rem;
}

@keyframes animation-fade-pulse_lightmode {
  0% {
    opacity: .3;
    background: #a99;
  }

  40% {
    opacity: 1;
    background: #a77;
  }

  80% {
    opacity: .3;
    background: #a99;
  }
}

@keyframes animation-fade-pulse_darkmode {
  0% {
    opacity: .3;
    background: #a9a;
  }

  40% {
    opacity: 1;
    background: #a7a;
  }

  80% {
    opacity: .3;
    background: #a9a;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animation-delayed-fade {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes turbulence {
  0% {
    filter: url("#turbulence_0");
  }

  12.5% {
    filter: url("#turbulence_1");
  }

  25% {
    filter: url("#turbulence_2");
  }

  37.5% {
    filter: url("#turbulence_3");
  }

  50% {
    filter: url("#turbulence_4");
  }

  62.5% {
    filter: url("#turbulence_5");
  }

  75% {
    filter: url("#turbulence_6");
  }

  100% {
    filter: url("#turbulence_7");
  }
}

._projects-module__projects {
  grid-template-columns: 1fr;
  gap: 1rem;
  display: grid;
}

@media only screen and (min-width: 768px) {
  ._projects-module__projects {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 1400px) {
  ._projects-module__projects {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 768px) {
  ._projects-module__see_more {
    grid-column: span 2;
  }
}

@media only screen and (min-width: 1400px) {
  ._projects-module__see_more {
    grid-column: span 1;
  }
}

@keyframes animation-fade-pulse_lightmode {
  0% {
    opacity: .3;
    background: #a99;
  }

  40% {
    opacity: 1;
    background: #a77;
  }

  80% {
    opacity: .3;
    background: #a99;
  }
}

@keyframes animation-fade-pulse_darkmode {
  0% {
    opacity: .3;
    background: #a9a;
  }

  40% {
    opacity: 1;
    background: #a7a;
  }

  80% {
    opacity: .3;
    background: #a9a;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animation-delayed-fade {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes turbulence {
  0% {
    filter: url("#turbulence_0");
  }

  12.5% {
    filter: url("#turbulence_1");
  }

  25% {
    filter: url("#turbulence_2");
  }

  37.5% {
    filter: url("#turbulence_3");
  }

  50% {
    filter: url("#turbulence_4");
  }

  62.5% {
    filter: url("#turbulence_5");
  }

  75% {
    filter: url("#turbulence_6");
  }

  100% {
    filter: url("#turbulence_7");
  }
}

._imageLightbox-module__image_lightbox {
  width: 100%;
}

._imageLightbox-module__interactive {
  cursor: pointer;
}

@keyframes animation-fade-pulse_lightmode {
  0% {
    opacity: .3;
    background: #a99;
  }

  40% {
    opacity: 1;
    background: #a77;
  }

  80% {
    opacity: .3;
    background: #a99;
  }
}

@keyframes animation-fade-pulse_darkmode {
  0% {
    opacity: .3;
    background: #a9a;
  }

  40% {
    opacity: 1;
    background: #a7a;
  }

  80% {
    opacity: .3;
    background: #a9a;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animation-delayed-fade {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes turbulence {
  0% {
    filter: url("#turbulence_0");
  }

  12.5% {
    filter: url("#turbulence_1");
  }

  25% {
    filter: url("#turbulence_2");
  }

  37.5% {
    filter: url("#turbulence_3");
  }

  50% {
    filter: url("#turbulence_4");
  }

  62.5% {
    filter: url("#turbulence_5");
  }

  75% {
    filter: url("#turbulence_6");
  }

  100% {
    filter: url("#turbulence_7");
  }
}

._photos-module__photos {
  grid-template-columns: 1fr;
  gap: 1rem;
  display: grid;
}

._photos-module__photos._photos-module__mini {
  opacity: 1;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  transition: opacity .2s ease-in-out;
}

._photos-module__photos._photos-module__mini._photos-module__loading {
  opacity: 0;
}

@media only screen and (min-width: 768px) {
  ._photos-module__photos {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1400px) {
  ._photos-module__photos._photos-module__mini {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

.theme--lightmode ._photos-module__photo {
  background-color: #211;
}

.theme--transition ._photos-module__photo {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--darkmode ._photos-module__photo {
  background-color: #000;
}

.theme--transition ._photos-module__photo {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

._photos-module__tile {
  border-radius: 0;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}

.theme--lightmode ._photos-module__tile {
  box-shadow: 0 0 0 1px #5d4444;
}

.theme--transition ._photos-module__tile {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--darkmode ._photos-module__tile {
  box-shadow: 0 0 0 1px #cdcccd;
}

.theme--transition ._photos-module__tile {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--lightmode ._photos-module__tile {
  background-color: #211;
}

.theme--transition ._photos-module__tile {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--darkmode ._photos-module__tile {
  background-color: #000;
}

.theme--transition ._photos-module__tile {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

@media (hover: hover) {
  ._photos-module__tile img {
    transition: transform .2s cubic-bezier(.45, .05, .55, .95);
  }

  ._photos-module__tile img:hover {
    transform: scale(1.04);
  }
}

@keyframes animation-fade-pulse_lightmode {
  0% {
    opacity: .3;
    background: #a99;
  }

  40% {
    opacity: 1;
    background: #a77;
  }

  80% {
    opacity: .3;
    background: #a99;
  }
}

@keyframes animation-fade-pulse_darkmode {
  0% {
    opacity: .3;
    background: #a9a;
  }

  40% {
    opacity: 1;
    background: #a7a;
  }

  80% {
    opacity: .3;
    background: #a9a;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animation-delayed-fade {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes turbulence {
  0% {
    filter: url("#turbulence_0");
  }

  12.5% {
    filter: url("#turbulence_1");
  }

  25% {
    filter: url("#turbulence_2");
  }

  37.5% {
    filter: url("#turbulence_3");
  }

  50% {
    filter: url("#turbulence_4");
  }

  62.5% {
    filter: url("#turbulence_5");
  }

  75% {
    filter: url("#turbulence_6");
  }

  100% {
    filter: url("#turbulence_7");
  }
}

._imageSvgOverlay-module__image_svg_overlay {
  position: relative;
}

._imageSvgOverlay-module__image_svg_overlay ._imageSvgOverlay-module__svg_overlay {
  z-index: 1;
  height: 100%;
  width: 100%;
  pointer-events: none;
  position: absolute;
  inset: 0;
}

@keyframes animation-fade-pulse_lightmode {
  0% {
    opacity: .3;
    background: #a99;
  }

  40% {
    opacity: 1;
    background: #a77;
  }

  80% {
    opacity: .3;
    background: #a99;
  }
}

@keyframes animation-fade-pulse_darkmode {
  0% {
    opacity: .3;
    background: #a9a;
  }

  40% {
    opacity: 1;
    background: #a7a;
  }

  80% {
    opacity: .3;
    background: #a9a;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animation-delayed-fade {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes turbulence {
  0% {
    filter: url("#turbulence_0");
  }

  12.5% {
    filter: url("#turbulence_1");
  }

  25% {
    filter: url("#turbulence_2");
  }

  37.5% {
    filter: url("#turbulence_3");
  }

  50% {
    filter: url("#turbulence_4");
  }

  62.5% {
    filter: url("#turbulence_5");
  }

  75% {
    filter: url("#turbulence_6");
  }

  100% {
    filter: url("#turbulence_7");
  }
}

._homepageImage-module__homepage_image {
  border-radius: 0;
  overflow: hidden;
}

.theme--lightmode ._homepageImage-module__homepage_image {
  box-shadow: 0 0 0 1px #5d4444;
}

.theme--transition ._homepageImage-module__homepage_image {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--darkmode ._homepageImage-module__homepage_image {
  box-shadow: 0 0 0 1px #cdcccd;
}

.theme--transition ._homepageImage-module__homepage_image {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--lightmode ._homepageImage-module__homepage_image {
  background-color: #211;
}

.theme--transition ._homepageImage-module__homepage_image {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--darkmode ._homepageImage-module__homepage_image {
  background-color: #000;
}

.theme--transition ._homepageImage-module__homepage_image {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

._homepageImage-module__homepage_image > ._homepageImage-module__homepage_image_svg {
  stroke-dasharray: 265;
  stroke-dashoffset: 265px;
  opacity: 0;
  transition: opacity .2s ease-in, stroke-dashoffset .2s ease-in-out;
}

@media (hover: hover) {
  ._homepageImage-module__homepage_image:hover > ._homepageImage-module__homepage_image_svg, ._homepageImage-module__homepage_image:active > ._homepageImage-module__homepage_image_svg {
    opacity: .8;
    stroke-dashoffset: 0;
    transition: opacity .3s ease-in, stroke-dashoffset .5s ease-in-out;
  }
}

@keyframes animation-fade-pulse_lightmode {
  0% {
    opacity: .3;
    background: #a99;
  }

  40% {
    opacity: 1;
    background: #a77;
  }

  80% {
    opacity: .3;
    background: #a99;
  }
}

@keyframes animation-fade-pulse_darkmode {
  0% {
    opacity: .3;
    background: #a9a;
  }

  40% {
    opacity: 1;
    background: #a7a;
  }

  80% {
    opacity: .3;
    background: #a9a;
  }
}

._contributions-module__contributions {
  cursor: pointer;
  text-align: center;
  opacity: 0;
  margin-bottom: -2rem;
  transition: opacity .5s ease-in-out;
}

._contributions-module__contributions rect {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

._contributions-module__contributions g:nth-last-of-type(1) {
  opacity: 0;
  transition: opacity .5s ease-in-out 30ms;
}

._contributions-module__contributions g:nth-last-of-type(2) {
  opacity: 0;
  transition: opacity .5s ease-in-out 60ms;
}

._contributions-module__contributions g:nth-last-of-type(3) {
  opacity: 0;
  transition: opacity .5s ease-in-out 90ms;
}

._contributions-module__contributions g:nth-last-of-type(4) {
  opacity: 0;
  transition: opacity .5s ease-in-out .12s;
}

._contributions-module__contributions g:nth-last-of-type(5) {
  opacity: 0;
  transition: opacity .5s ease-in-out .15s;
}

._contributions-module__contributions g:nth-last-of-type(6) {
  opacity: 0;
  transition: opacity .5s ease-in-out .18s;
}

._contributions-module__contributions g:nth-last-of-type(7) {
  opacity: 0;
  transition: opacity .5s ease-in-out .21s;
}

._contributions-module__contributions g:nth-last-of-type(8) {
  opacity: 0;
  transition: opacity .5s ease-in-out .24s;
}

._contributions-module__contributions g:nth-last-of-type(9) {
  opacity: 0;
  transition: opacity .5s ease-in-out .27s;
}

._contributions-module__contributions g:nth-last-of-type(10) {
  opacity: 0;
  transition: opacity .5s ease-in-out .3s;
}

._contributions-module__contributions g:nth-last-of-type(11) {
  opacity: 0;
  transition: opacity .5s ease-in-out .33s;
}

._contributions-module__contributions g:nth-last-of-type(12) {
  opacity: 0;
  transition: opacity .5s ease-in-out .36s;
}

._contributions-module__contributions g:nth-last-of-type(13) {
  opacity: 0;
  transition: opacity .5s ease-in-out .39s;
}

._contributions-module__contributions g:nth-last-of-type(14) {
  opacity: 0;
  transition: opacity .5s ease-in-out .42s;
}

._contributions-module__contributions g:nth-last-of-type(15) {
  opacity: 0;
  transition: opacity .5s ease-in-out .45s;
}

._contributions-module__contributions g:nth-last-of-type(16) {
  opacity: 0;
  transition: opacity .5s ease-in-out .48s;
}

._contributions-module__contributions g:nth-last-of-type(17) {
  opacity: 0;
  transition: opacity .5s ease-in-out .51s;
}

._contributions-module__contributions g:nth-last-of-type(18) {
  opacity: 0;
  transition: opacity .5s ease-in-out .54s;
}

._contributions-module__contributions g:nth-last-of-type(19) {
  opacity: 0;
  transition: opacity .5s ease-in-out .57s;
}

._contributions-module__contributions g:nth-last-of-type(20) {
  opacity: 0;
  transition: opacity .5s ease-in-out .6s;
}

._contributions-module__contributions g:nth-last-of-type(21) {
  opacity: 0;
  transition: opacity .5s ease-in-out .63s;
}

._contributions-module__contributions g:nth-last-of-type(22) {
  opacity: 0;
  transition: opacity .5s ease-in-out .66s;
}

._contributions-module__contributions g:nth-last-of-type(23) {
  opacity: 0;
  transition: opacity .5s ease-in-out .69s;
}

._contributions-module__contributions g:nth-last-of-type(24) {
  opacity: 0;
  transition: opacity .5s ease-in-out .72s;
}

._contributions-module__contributions g:nth-last-of-type(25) {
  opacity: 0;
  transition: opacity .5s ease-in-out .75s;
}

._contributions-module__contributions g:nth-last-of-type(26) {
  opacity: 0;
  transition: opacity .5s ease-in-out .78s;
}

._contributions-module__contributions g:nth-last-of-type(27) {
  opacity: 0;
  transition: opacity .5s ease-in-out .81s;
}

._contributions-module__contributions g:nth-last-of-type(28) {
  opacity: 0;
  transition: opacity .5s ease-in-out .84s;
}

._contributions-module__contributions g:nth-last-of-type(29) {
  opacity: 0;
  transition: opacity .5s ease-in-out .87s;
}

._contributions-module__contributions g:nth-last-of-type(30) {
  opacity: 0;
  transition: opacity .5s ease-in-out .9s;
}

._contributions-module__contributions g:nth-last-of-type(31) {
  opacity: 0;
  transition: opacity .5s ease-in-out .93s;
}

._contributions-module__contributions g:nth-last-of-type(32) {
  opacity: 0;
  transition: opacity .5s ease-in-out .96s;
}

._contributions-module__contributions g:nth-last-of-type(33) {
  opacity: 0;
  transition: opacity .5s ease-in-out .99s;
}

._contributions-module__contributions g:nth-last-of-type(34) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.02s;
}

._contributions-module__contributions g:nth-last-of-type(35) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.05s;
}

._contributions-module__contributions g:nth-last-of-type(36) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.08s;
}

._contributions-module__contributions g:nth-last-of-type(37) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.11s;
}

._contributions-module__contributions g:nth-last-of-type(38) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.14s;
}

._contributions-module__contributions g:nth-last-of-type(39) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.17s;
}

._contributions-module__contributions g:nth-last-of-type(40) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.2s;
}

._contributions-module__contributions g:nth-last-of-type(41) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.23s;
}

._contributions-module__contributions g:nth-last-of-type(42) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.26s;
}

._contributions-module__contributions g:nth-last-of-type(43) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.29s;
}

._contributions-module__contributions g:nth-last-of-type(44) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.32s;
}

._contributions-module__contributions g:nth-last-of-type(45) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.35s;
}

._contributions-module__contributions g:nth-last-of-type(46) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.38s;
}

._contributions-module__contributions g:nth-last-of-type(47) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.41s;
}

._contributions-module__contributions g:nth-last-of-type(48) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.44s;
}

._contributions-module__contributions g:nth-last-of-type(49) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.47s;
}

._contributions-module__contributions g:nth-last-of-type(50) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.5s;
}

._contributions-module__contributions g:nth-last-of-type(51) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.53s;
}

._contributions-module__contributions g:nth-last-of-type(52) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.56s;
}

._contributions-module__contributions g:nth-last-of-type(53) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.59s;
}

._contributions-module__contributions g:nth-last-of-type(54) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.62s;
}

._contributions-module__contributions g:nth-last-of-type(55) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.65s;
}

._contributions-module__contributions g:nth-last-of-type(56) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.68s;
}

._contributions-module__contributions g:nth-last-of-type(57) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.71s;
}

._contributions-module__contributions g:nth-last-of-type(58) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.74s;
}

._contributions-module__contributions g:nth-last-of-type(59) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.77s;
}

._contributions-module__contributions g:nth-last-of-type(60) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.8s;
}

._contributions-module__contributions g:nth-last-of-type(61) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.83s;
}

._contributions-module__contributions g:nth-last-of-type(62) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.86s;
}

._contributions-module__contributions g:nth-last-of-type(63) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.89s;
}

._contributions-module__contributions g:nth-last-of-type(64) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.92s;
}

._contributions-module__contributions g:nth-last-of-type(65) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.95s;
}

._contributions-module__contributions g:nth-last-of-type(66) {
  opacity: 0;
  transition: opacity .5s ease-in-out 1.98s;
}

._contributions-module__contributions g:nth-last-of-type(67) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.01s;
}

._contributions-module__contributions g:nth-last-of-type(68) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.04s;
}

._contributions-module__contributions g:nth-last-of-type(69) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.07s;
}

._contributions-module__contributions g:nth-last-of-type(70) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.1s;
}

._contributions-module__contributions g:nth-last-of-type(71) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.13s;
}

._contributions-module__contributions g:nth-last-of-type(72) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.16s;
}

._contributions-module__contributions g:nth-last-of-type(73) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.19s;
}

._contributions-module__contributions g:nth-last-of-type(74) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.22s;
}

._contributions-module__contributions g:nth-last-of-type(75) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.25s;
}

._contributions-module__contributions g:nth-last-of-type(76) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.28s;
}

._contributions-module__contributions g:nth-last-of-type(77) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.31s;
}

._contributions-module__contributions g:nth-last-of-type(78) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.34s;
}

._contributions-module__contributions g:nth-last-of-type(79) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.37s;
}

._contributions-module__contributions g:nth-last-of-type(80) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.4s;
}

._contributions-module__contributions g:nth-last-of-type(81) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.43s;
}

._contributions-module__contributions g:nth-last-of-type(82) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.46s;
}

._contributions-module__contributions g:nth-last-of-type(83) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.49s;
}

._contributions-module__contributions g:nth-last-of-type(84) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.52s;
}

._contributions-module__contributions g:nth-last-of-type(85) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.55s;
}

._contributions-module__contributions g:nth-last-of-type(86) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.58s;
}

._contributions-module__contributions g:nth-last-of-type(87) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.61s;
}

._contributions-module__contributions g:nth-last-of-type(88) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.64s;
}

._contributions-module__contributions g:nth-last-of-type(89) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.67s;
}

._contributions-module__contributions g:nth-last-of-type(90) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.7s;
}

._contributions-module__contributions g:nth-last-of-type(91) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.73s;
}

._contributions-module__contributions g:nth-last-of-type(92) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.76s;
}

._contributions-module__contributions g:nth-last-of-type(93) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.79s;
}

._contributions-module__contributions g:nth-last-of-type(94) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.82s;
}

._contributions-module__contributions g:nth-last-of-type(95) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.85s;
}

._contributions-module__contributions g:nth-last-of-type(96) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.88s;
}

._contributions-module__contributions g:nth-last-of-type(97) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.91s;
}

._contributions-module__contributions g:nth-last-of-type(98) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.94s;
}

._contributions-module__contributions g:nth-last-of-type(99) {
  opacity: 0;
  transition: opacity .5s ease-in-out 2.97s;
}

._contributions-module__contributions g:nth-last-of-type(100) {
  opacity: 0;
  transition: opacity .5s ease-in-out 3s;
}

._contributions-module__contributions span {
  padding-top: 3px;
  font-size: .75em;
}

._contributions-module__contributions text, ._contributions-module__contributions span {
  opacity: 0;
  transition: opacity .3s ease-in-out;
}

._contributions-module__contributions._contributions-module__loaded, ._contributions-module__contributions._contributions-module__loaded g, ._contributions-module__contributions._contributions-module__loaded:hover text, ._contributions-module__contributions._contributions-module__loaded:hover span {
  opacity: 1;
}

@keyframes animation-fade-pulse_lightmode {
  0% {
    opacity: .3;
    background: #a99;
  }

  40% {
    opacity: 1;
    background: #a77;
  }

  80% {
    opacity: .3;
    background: #a99;
  }
}

@keyframes animation-fade-pulse_darkmode {
  0% {
    opacity: .3;
    background: #a9a;
  }

  40% {
    opacity: 1;
    background: #a7a;
  }

  80% {
    opacity: .3;
    background: #a9a;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animation-delayed-fade {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes turbulence {
  0% {
    filter: url("#turbulence_0");
  }

  12.5% {
    filter: url("#turbulence_1");
  }

  25% {
    filter: url("#turbulence_2");
  }

  37.5% {
    filter: url("#turbulence_3");
  }

  50% {
    filter: url("#turbulence_4");
  }

  62.5% {
    filter: url("#turbulence_5");
  }

  75% {
    filter: url("#turbulence_6");
  }

  100% {
    filter: url("#turbulence_7");
  }
}

._iconParagraph-module__paragraph {
  display: flex;
}

._iconParagraph-module__paragraph ._iconParagraph-module__icon {
  align-self: center;
  margin: 0 .6rem 0 0;
}

.theme--lightmode ._iconParagraph-module__paragraph ._iconParagraph-module__icon {
  color: #a99;
}

.theme--transition ._iconParagraph-module__paragraph ._iconParagraph-module__icon {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--darkmode ._iconParagraph-module__paragraph ._iconParagraph-module__icon {
  color: #a9a;
}

.theme--transition ._iconParagraph-module__paragraph ._iconParagraph-module__icon {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

._iconParagraph-module__paragraph ._iconParagraph-module__content {
  flex: 1;
}

@keyframes animation-fade-pulse_lightmode {
  0% {
    opacity: .3;
    background: #a99;
  }

  40% {
    opacity: 1;
    background: #a77;
  }

  80% {
    opacity: .3;
    background: #a99;
  }
}

@keyframes animation-fade-pulse_darkmode {
  0% {
    opacity: .3;
    background: #a9a;
  }

  40% {
    opacity: 1;
    background: #a7a;
  }

  80% {
    opacity: .3;
    background: #a9a;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animation-delayed-fade {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes turbulence {
  0% {
    filter: url("#turbulence_0");
  }

  12.5% {
    filter: url("#turbulence_1");
  }

  25% {
    filter: url("#turbulence_2");
  }

  37.5% {
    filter: url("#turbulence_3");
  }

  50% {
    filter: url("#turbulence_4");
  }

  62.5% {
    filter: url("#turbulence_5");
  }

  75% {
    filter: url("#turbulence_6");
  }

  100% {
    filter: url("#turbulence_7");
  }
}

._homepageAbout-module__homepage_about {
  border-radius: 0;
  padding: 1rem 1rem 0;
}

._homepageAbout-module__homepage_about ._homepageAbout-module__github_calendar {
  margin: 2.5rem 0 0;
  display: none;
}

@media only screen and (min-width: 768px) {
  ._homepageAbout-module__homepage_about ._homepageAbout-module__github_calendar {
    display: block;
  }

  ._homepageAbout-module__homepage_about {
    padding: 1.5rem 3rem;
  }

  .theme--lightmode ._homepageAbout-module__homepage_about {
    border: 2px dashed #5d4444;
  }

  .theme--transition ._homepageAbout-module__homepage_about {
    transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
  }

  .theme--darkmode ._homepageAbout-module__homepage_about {
    border: 2px dashed #cdcccd;
  }

  .theme--transition ._homepageAbout-module__homepage_about {
    transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
  }
}

._homepageAbout-module__homepage_about ._homepageAbout-module__theme_buttons {
  margin-left: .75rem;
}

._homepageAbout-module__homepage_about br + p {
  margin-top: 0;
}

._homepageAbout-module__homepage_about strong {
  font-weight: bold;
}

._homepageAbout-module__homepage_about a {
  text-underline-offset: 2px;
  margin: -1px -2px -2px;
  padding: 1px 2px 2px;
  transition: background-size .2s;
}

.theme--lightmode ._homepageAbout-module__homepage_about a {
  color: #a77;
  background: linear-gradient(to right, #edd, #edd) 0 100% / 100% 0 no-repeat;
}

.theme--lightmode ._homepageAbout-module__homepage_about a:hover {
  background-size: 100% 100%;
}

.theme--transition ._homepageAbout-module__homepage_about a {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--darkmode ._homepageAbout-module__homepage_about a {
  color: #c9c;
  background: linear-gradient(to right, #545, #545) 0 100% / 100% 0 no-repeat;
}

.theme--darkmode ._homepageAbout-module__homepage_about a:hover {
  background-size: 100% 100%;
}

.theme--transition ._homepageAbout-module__homepage_about a {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

._homepageAbout-module__bottom_half {
  min-height: 9rem;
  display: grid;
}

._homepageAbout-module__bottom_half ._homepageAbout-module__contributions {
  height: 100%;
  display: none;
}

@media only screen and (min-width: 1400px) {
  ._homepageAbout-module__bottom_half {
    grid-gap: 2rem;
    grid-template-columns: 4fr 3fr;
  }

  ._homepageAbout-module__bottom_half ._homepageAbout-module__contributions {
    display: block;
  }
}

@keyframes animation-fade-pulse_lightmode {
  0% {
    opacity: .3;
    background: #a99;
  }

  40% {
    opacity: 1;
    background: #a77;
  }

  80% {
    opacity: .3;
    background: #a99;
  }
}

@keyframes animation-fade-pulse_darkmode {
  0% {
    opacity: .3;
    background: #a9a;
  }

  40% {
    opacity: 1;
    background: #a7a;
  }

  80% {
    opacity: .3;
    background: #a9a;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animation-delayed-fade {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes turbulence {
  0% {
    filter: url("#turbulence_0");
  }

  12.5% {
    filter: url("#turbulence_1");
  }

  25% {
    filter: url("#turbulence_2");
  }

  37.5% {
    filter: url("#turbulence_3");
  }

  50% {
    filter: url("#turbulence_4");
  }

  62.5% {
    filter: url("#turbulence_5");
  }

  75% {
    filter: url("#turbulence_6");
  }

  100% {
    filter: url("#turbulence_7");
  }
}

._homepage-module__homepage {
  grid-template-columns: 1fr;
  gap: 1rem;
  display: grid;
}

@media only screen and (min-width: 768px) {
  ._homepage-module__homepage {
    grid-template-columns: 5fr 8fr;
  }
}

._homepage-module__homepage ._homepage-module__preview {
  margin-top: 1rem;
}

._homepage-module__homepage ._homepage-module__preview section > article {
  margin: 0;
}

@media only screen and (min-width: 768px) {
  ._homepage-module__homepage ._homepage-module__preview {
    grid-column: span 2;
    margin-top: 1.5rem;
  }
}

@keyframes animation-fade-pulse_lightmode {
  0% {
    opacity: .3;
    background: #a99;
  }

  40% {
    opacity: 1;
    background: #a77;
  }

  80% {
    opacity: .3;
    background: #a99;
  }
}

@keyframes animation-fade-pulse_darkmode {
  0% {
    opacity: .3;
    background: #a9a;
  }

  40% {
    opacity: 1;
    background: #a7a;
  }

  80% {
    opacity: .3;
    background: #a9a;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animation-delayed-fade {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes turbulence {
  0% {
    filter: url("#turbulence_0");
  }

  12.5% {
    filter: url("#turbulence_1");
  }

  25% {
    filter: url("#turbulence_2");
  }

  37.5% {
    filter: url("#turbulence_3");
  }

  50% {
    filter: url("#turbulence_4");
  }

  62.5% {
    filter: url("#turbulence_5");
  }

  75% {
    filter: url("#turbulence_6");
  }

  100% {
    filter: url("#turbulence_7");
  }
}

._subnavigation-module__subnavigation {
  border-radius: 0;
  margin: 0 0 2rem;
  padding: 0;
  display: grid;
  position: relative;
  overflow: hidden;
}

.theme--lightmode ._subnavigation-module__subnavigation {
  box-shadow: 0 0 0 1px #5d4444;
}

.theme--transition ._subnavigation-module__subnavigation {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--darkmode ._subnavigation-module__subnavigation {
  box-shadow: 0 0 0 1px #cdcccd;
}

.theme--transition ._subnavigation-module__subnavigation {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--lightmode ._subnavigation-module__subnavigation {
  background-color: #0000;
}

.theme--transition ._subnavigation-module__subnavigation {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--darkmode ._subnavigation-module__subnavigation {
  background-color: #0000;
}

.theme--transition ._subnavigation-module__subnavigation {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

@media only screen and (min-width: 768px) {
  ._subnavigation-module__subnavigation {
    grid-template-columns: 1fr auto;
  }
}

._subnavigation-module__subnavigation ul {
  flex-flow: column wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

._subnavigation-module__subnavigation ul:nth-child(2) {
  flex-direction: row-reverse;
  display: flex;
}

@media only screen and (min-width: 768px) {
  ._subnavigation-module__subnavigation ul {
    flex-direction: row;
  }
}

._subnavigation-module__subnavigation ul > li {
  width: 100%;
  align-items: center;
  display: flex;
  position: relative;
}

@media only screen and (min-width: 768px) {
  ._subnavigation-module__subnavigation ul > li {
    width: min-content;
  }
}

._subnavigation-module__subnavigation ul > li > * {
  width: 100%;
  padding: 0 1.25rem;
  line-height: 2rem;
}

._subnavigation-module__subnavigation ul:first-child > li {
  cursor: pointer;
}

@media (hover: hover) {
  ._subnavigation-module__subnavigation ul:first-child > li:after {
    pointer-events: none;
    opacity: 0;
    content: "";
    width: calc(100% - .5rem);
    height: calc(100% - .5rem);
    border-radius: 2px;
    transition: opacity .2s cubic-bezier(.45, .05, .55, .95);
    position: absolute;
    inset: .25rem;
    overflow: hidden;
  }

  .theme--lightmode ._subnavigation-module__subnavigation ul:first-child > li:after {
    color: #211;
    background: #5d4444;
  }

  .theme--transition ._subnavigation-module__subnavigation ul:first-child > li:after {
    transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
  }

  .theme--darkmode ._subnavigation-module__subnavigation ul:first-child > li:after {
    color: #ede;
    background: #cdcccd;
  }

  .theme--transition ._subnavigation-module__subnavigation ul:first-child > li:after {
    transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
  }

  ._subnavigation-module__subnavigation ul:first-child > li:hover:after {
    opacity: .2;
  }

  ._subnavigation-module__subnavigation ul:first-child > li:active:after {
    opacity: .4;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    transition: none;
    inset: 0;
  }

  .theme--lightmode ._subnavigation-module__subnavigation ul:first-child > li:active:after {
    color: #211;
    background: #5d4444;
  }

  .theme--transition ._subnavigation-module__subnavigation ul:first-child > li:active:after {
    transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
  }

  .theme--darkmode ._subnavigation-module__subnavigation ul:first-child > li:active:after {
    color: #ede;
    background: #cdcccd;
  }

  .theme--transition ._subnavigation-module__subnavigation ul:first-child > li:active:after {
    transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
  }
}

._subnavigation-module__subnavigation ul:first-child > li > a {
  white-space: nowrap;
  padding: 0 1.25rem;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.theme--lightmode ._subnavigation-module__subnavigation ul:first-child > li > a {
  color: #211;
}

.theme--transition ._subnavigation-module__subnavigation ul:first-child > li > a {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--darkmode ._subnavigation-module__subnavigation ul:first-child > li > a {
  color: #ede;
}

.theme--transition ._subnavigation-module__subnavigation ul:first-child > li > a {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--lightmode ._subnavigation-module__subnavigation ul:first-child > li._subnavigation-module__current {
  background: #211;
}

.theme--lightmode ._subnavigation-module__subnavigation ul:first-child > li._subnavigation-module__current a {
  color: #fff;
}

.theme--transition ._subnavigation-module__subnavigation ul:first-child > li {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--darkmode ._subnavigation-module__subnavigation ul:first-child > li._subnavigation-module__current {
  background: #ede;
}

.theme--darkmode ._subnavigation-module__subnavigation ul:first-child > li._subnavigation-module__current a {
  color: #000;
}

.theme--transition ._subnavigation-module__subnavigation ul:first-child > li {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--lightmode ._subnavigation-module__subnavigation ul:nth-child(2) {
  border-top: 2px solid #a99;
  margin-top: .5rem;
  padding: .5rem 0;
}

@media only screen and (min-width: 768px) {
  .theme--lightmode ._subnavigation-module__subnavigation ul:nth-child(2) {
    border: none;
    margin: 0;
    padding: 0;
  }
}

.theme--transition ._subnavigation-module__subnavigation ul:nth-child(2) {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--darkmode ._subnavigation-module__subnavigation ul:nth-child(2) {
  border-top: 2px solid #a9a;
  margin-top: .5rem;
  padding: .5rem 0;
}

@media only screen and (min-width: 768px) {
  .theme--darkmode ._subnavigation-module__subnavigation ul:nth-child(2) {
    border: none;
    margin: 0;
    padding: 0;
  }
}

.theme--transition ._subnavigation-module__subnavigation ul:nth-child(2) {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

@keyframes animation-fade-pulse_lightmode {
  0% {
    opacity: .3;
    background: #a99;
  }

  40% {
    opacity: 1;
    background: #a77;
  }

  80% {
    opacity: .3;
    background: #a99;
  }
}

@keyframes animation-fade-pulse_darkmode {
  0% {
    opacity: .3;
    background: #a9a;
  }

  40% {
    opacity: 1;
    background: #a7a;
  }

  80% {
    opacity: .3;
    background: #a9a;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animation-delayed-fade {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes turbulence {
  0% {
    filter: url("#turbulence_0");
  }

  12.5% {
    filter: url("#turbulence_1");
  }

  25% {
    filter: url("#turbulence_2");
  }

  37.5% {
    filter: url("#turbulence_3");
  }

  50% {
    filter: url("#turbulence_4");
  }

  62.5% {
    filter: url("#turbulence_5");
  }

  75% {
    filter: url("#turbulence_6");
  }

  100% {
    filter: url("#turbulence_7");
  }
}

._projectsFigrid-module__projects_figrid {
  grid-template-columns: 1fr;
  gap: 2rem;
  display: grid;
}

@media only screen and (min-width: 768px) {
  ._projectsFigrid-module__projects_figrid {
    grid-template-columns: 1fr 1fr;
  }
}

._projectsFigrid-module__projects_figrid > div:first-child {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  display: flex;
}

.theme--lightmode ._projectsFigrid-module__projects_figrid > div:first-child {
  border: 2px solid #a99;
}

.theme--transition ._projectsFigrid-module__projects_figrid > div:first-child {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--darkmode ._projectsFigrid-module__projects_figrid > div:first-child {
  border: 2px solid #a9a;
}

.theme--transition ._projectsFigrid-module__projects_figrid > div:first-child {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

._projectsFigrid-module__projects_figrid > div:last-child {
  padding: 1rem;
}

._projectsFigrid-module__projects_figrid > div:last-child > div {
  height: 100%;
  width: 100%;
}

.theme--lightmode ._projectsFigrid-module__projects_figrid > div:last-child {
  background: #211;
  border: 2px solid #a99;
}

.theme--transition ._projectsFigrid-module__projects_figrid > div:last-child {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--darkmode ._projectsFigrid-module__projects_figrid > div:last-child {
  background: #000;
  border: 2px solid #a9a;
}

.theme--transition ._projectsFigrid-module__projects_figrid > div:last-child {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

._projectsFigrid-module__projects_figrid h4 {
  text-align: center;
  margin-top: 1rem;
}

._projectsFigrid-module__projects_figrid a {
  text-underline-offset: 2px;
  white-space: nowrap;
  margin: -1px -2px -2px;
  padding: 1px 2px 2px;
  transition: background-size .2s;
}

.theme--lightmode ._projectsFigrid-module__projects_figrid a {
  color: #a77;
  background: linear-gradient(to right, #edd, #edd) 0 100% / 100% 0 no-repeat;
}

.theme--lightmode ._projectsFigrid-module__projects_figrid a:hover {
  background-size: 100% 100%;
}

.theme--transition ._projectsFigrid-module__projects_figrid a {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--darkmode ._projectsFigrid-module__projects_figrid a {
  color: #c9c;
  background: linear-gradient(to right, #545, #545) 0 100% / 100% 0 no-repeat;
}

.theme--darkmode ._projectsFigrid-module__projects_figrid a:hover {
  background-size: 100% 100%;
}

.theme--transition ._projectsFigrid-module__projects_figrid a {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

@keyframes animation-fade-pulse_lightmode {
  0% {
    opacity: .3;
    background: #a99;
  }

  40% {
    opacity: 1;
    background: #a77;
  }

  80% {
    opacity: .3;
    background: #a99;
  }
}

@keyframes animation-fade-pulse_darkmode {
  0% {
    opacity: .3;
    background: #a9a;
  }

  40% {
    opacity: 1;
    background: #a7a;
  }

  80% {
    opacity: .3;
    background: #a9a;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animation-delayed-fade {
  0% {
    opacity: 0;
  }

  33% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes turbulence {
  0% {
    filter: url("#turbulence_0");
  }

  12.5% {
    filter: url("#turbulence_1");
  }

  25% {
    filter: url("#turbulence_2");
  }

  37.5% {
    filter: url("#turbulence_3");
  }

  50% {
    filter: url("#turbulence_4");
  }

  62.5% {
    filter: url("#turbulence_5");
  }

  75% {
    filter: url("#turbulence_6");
  }

  100% {
    filter: url("#turbulence_7");
  }
}

@media only screen and (min-width: 1400px) {
  html {
    margin-left: calc(100vw - 100%);
    margin-right: 0;
  }
}

body {
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-weight: 300;
}

.theme--lightmode body {
  color: #111;
  background: #fff;
}

.theme--transition body {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

.theme--darkmode body {
  color: #fff;
  background: #000;
}

.theme--transition body {
  transition: color .2s ease-out, background .2s ease-out, background-color .2s ease-out, border-color .2s ease-out, box-shadow .2s ease-out, text-shadow .2s ease-out, stroke .2s ease-out, filter .2s ease-out, fill .2s ease-out;
}

p {
  line-height: 1.5rem;
}

@media only screen and (min-width: 768px) {
  p {
    text-align: justify;
  }
}

/*# sourceMappingURL=index.cb74fe23.css.map */
