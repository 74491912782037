$color-black: "color-black";
$color-transparent: "color-transparent";
$color-text-default: "color-text-default";
$color-text-link: "color-text-link";
$color-background-default: "color-background-default";
$color-background-container: "color-background-container";
$color-primary: "color-primary";
$color-secondary: "color-secondary";
$color-tertiary: "color-tertiary";
$color-quaternary: "color-quaternary";
$color-quinary: "color-quinary";
$animation-fade-pulse: "animation-fade-pulse";
$svg-brightness: "svg-brightness";

$themes: (
  lightmode: (
    color-black: #000000,
    color-transparent: #0000,
    color-text-default: #111111,
    color-text-link: #aa7777,
    color-background-default: #ffffff,
    color-background-container: #221111,
    color-primary: #aa7777,
    color-secondary: #aa9999,
    color-tertiary: #eedddd,
    color-quaternary: #5d4444,
    color-quinary: #221111,
    animation-fade-pulse: animation-fade-pulse_lightmode,
    svg-brightness: 0,
  ),
  darkmode: (
    color-black: #000000,
    color-transparent: #0000,
    color-text-default: #ffffff,
    color-text-link: #cc99cc,
    color-background-default: #000000,
    color-background-container: #000000,
    color-primary: #aa77aa,
    color-secondary: #aa99aa,
    color-tertiary: #554455,
    color-quaternary: #cdcccd,
    color-quinary: #eeddee,
    animation-fade-pulse: animation-fade-pulse_darkmode,
    svg-brightness: 0.9,
  ),
);

@mixin themed() {
  @each $theme, $map in $themes {
    :global(.theme--#{$theme}) & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get($map, "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
    :global(.theme--transition) & {
      @include color_transitions();
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}

@function value_by_theme($theme, $key) {
  @return map-get(map-get($themes, $theme), "#{$key}");
}

@mixin color_transitions() {
  transition: color 200ms ease-out, background 200ms ease-out,
    background-color 200ms ease-out, border-color 200ms ease-out,
    box-shadow 200ms ease-out, text-shadow 200ms ease-out, stroke 200ms ease-out,
    filter 200ms ease-out, fill 200ms ease-out;
}

@each $theme, $map in $themes {
  @keyframes :global(animation-fade-pulse_#{$theme}) {
    0% {
      opacity: 0.3;
      background: map-get($map, $color-secondary);
    }

    40% {
      opacity: 1;
      background: map-get($map, $color-primary);
    }

    80% {
      opacity: 0.3;
      background: map-get($map, $color-secondary);
    }
  }
}
